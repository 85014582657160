import { getContext } from '@fiverr-private/fiverr_context';
import { getBuyerAssumedLanguage, AssumedLanguageParams } from '@fiverr-private/localization';
import { bigQuery } from '@fiverr-private/obs';

/**
 * Handles client side initialization
 */
const main = () => {
    const { locale, browserLanguage, countryCode, currency, computedLocale } = getContext();
    const assumedLanguageParams: AssumedLanguageParams = {
        countryCode,
        locale,
        browserLanguage
    };
    const visitorAssumedLanguage = getBuyerAssumedLanguage(assumedLanguageParams);

    bigQuery.send({
        type: 'localization_attributes_per_page',
        group: 'technical_events',
        domain: {
            name: 'Localization'
        },
        localization: {
            experience_locale: locale,
            visitor_assumed_language: visitorAssumedLanguage,
            display_currency: currency,
            formatting_locale: computedLocale
        }
    });
};

export default main;
